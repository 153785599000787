import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     

<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m8410 2204 c0 -3 -21 -35 -47 -72 -26 -37 -93 -132 -148 -212 -56 -80
-121 -172 -144 -205 -23 -33 -57 -81 -74 -107 l-32 -48 -1122 0 -1123 0 0
-227 0 -228 -153 -241 c-83 -132 -161 -253 -173 -269 l-21 -27 -45 68 c-57 87
-134 207 -226 351 l-72 112 0 230 0 231 -1124 0 -1124 0 -158 223 c-86 122
-180 254 -208 292 -29 39 -62 85 -75 103 l-22 32 3045 0 c1675 0 3046 -3 3046
-6z m-3578 -1045 c24 -39 198 -316 343 -545 22 -34 74 -117 115 -183 41 -67
78 -121 82 -121 8 0 39 47 218 330 78 124 164 259 191 300 26 41 71 112 99
157 l51 82 877 -6 c482 -3 879 -7 881 -9 2 -2 -8 -21 -22 -41 -29 -42 -28 -41
-257 -362 l-165 -231 -132 0 -133 0 0 -845 0 -844 -32 -38 c-18 -21 -49 -56
-69 -78 -36 -40 -103 -116 -479 -540 -112 -126 -235 -265 -274 -309 -39 -43
-74 -83 -78 -89 -4 -6 -9 -9 -12 -7 -2 3 -8 215 -12 472 l-7 466 119 139 c65
76 122 140 125 143 8 6 17 1500 8 1500 -5 0 -289 -354 -688 -859 -80 -101
-160 -200 -177 -220 l-31 -35 -129 164 c-126 159 -531 669 -683 860 l-76 95
-7 -85 c-3 -47 -6 -384 -7 -748 l-1 -664 125 -136 125 -137 0 -473 c0 -348 -3
-471 -11 -466 -6 4 -28 25 -48 48 -20 22 -63 70 -96 106 -33 36 -99 108 -146
160 -47 52 -173 191 -280 309 -107 118 -234 259 -283 313 l-89 100 4 846 4
847 -132 3 -133 3 -68 92 c-105 143 -262 357 -296 405 -17 24 -48 65 -68 92
-20 26 -34 51 -31 54 4 3 404 6 890 6 l883 0 12 -21z m498 -1941 c25 -32 47
-57 50 -57 3 -1 77 89 165 199 l160 201 6 -93 c4 -51 7 -454 8 -895 l1 -803
-97 0 c-54 0 -209 -3 -345 -7 l-248 -6 0 913 0 912 128 -154 c70 -84 147 -179
172 -210z m-850 -1593 c49 -25 60 -60 60 -190 l0 -115 -155 0 -155 0 0 40 0
40 -70 0 -70 0 0 -60 0 -60 223 -2 222 -3 3 -367 c1 -229 -1 -368 -7 -368 -6
0 -40 11 -78 24 -126 44 -271 86 -294 86 -22 0 -95 -22 -289 -86 -41 -13 -78
-24 -82 -24 -5 0 -8 99 -8 220 l0 220 155 0 155 0 0 -50 0 -50 70 0 70 0 0 75
0 75 -225 0 -225 0 0 305 0 305 335 0 c281 0 341 -2 365 -15z m440 -365 l0
-380 70 0 70 0 0 380 0 380 120 0 c118 0 120 0 152 -29 l33 -29 3 -521 c1
-287 0 -521 -4 -521 -3 0 -84 25 -179 55 -94 30 -180 55 -189 55 -10 0 -97
-25 -195 -55 -98 -30 -181 -55 -184 -55 -4 0 -7 228 -7 506 0 449 2 509 16
533 30 51 58 60 182 61 l112 0 0 -380z m1239 354 c41 -34 51 -77 51 -218 l0
-118 -66 -62 -66 -62 66 -64 66 -64 0 -243 c0 -172 -3 -243 -11 -243 -6 0 -91
26 -188 57 -97 31 -183 56 -191 54 -8 -1 -92 -27 -185 -57 -94 -30 -175 -54
-181 -54 -7 0 -10 13 -8 33 1 17 3 265 3 550 l1 517 339 0 339 0 31 -26z m827
10 c44 -30 54 -63 54 -189 l0 -115 -155 0 -155 0 0 40 0 40 -70 0 -70 0 0 -60
0 -60 225 0 225 0 0 -370 c0 -203 -3 -370 -7 -370 -3 0 -84 25 -180 55 -96 30
-184 55 -195 55 -11 0 -98 -25 -193 -55 -94 -30 -175 -55 -178 -55 -4 0 -7 99
-7 220 l0 221 153 -3 152 -3 3 -47 3 -48 70 0 69 0 0 75 0 75 -225 0 -225 0 0
305 0 305 340 0 c300 0 344 -2 366 -16z"/>
<path d="M5756 2785 c-9 -24 -7 -93 2 -99 4 -2 37 -6 72 -8 l65 -3 0 60 0 60
-66 3 c-54 2 -68 0 -73 -13z"/>
<path d="M5754 2417 c-3 -8 -4 -41 -2 -73 l3 -59 73 -3 73 -3 -3 73 -3 73 -68
3 c-51 2 -69 -1 -73 -11z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
